.inputWrapper{
    border: 1px solid #CACACA;
    width: 100%;
    height: 51px;
    padding: 0 15px;
    background: #FFFFFF;
    border-radius: 100px;
    position: relative;
}

.inputWrapper input{
   border: none;
   width: 100%;
}

.inputWrapper input:focus{
    outline: none;
}

.inputWrapper input::placeholder{
    color: #CACACA;
}
.scheduledLessonsCard{
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    border: 1px dashed #C2C2C2;
    font-size: 20px;
    font-weight: 275;
    line-height: 30px;
    text-align: center;
    z-index: 1;
}

.scheduledLessonsCard h4{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.scheduledLessonsCard h5{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
}

.detailsButton{
    z-index: 100;
}
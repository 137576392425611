.main{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 0;
}

.learnerTable tr th, .learnerTable tr td{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #262626;
    text-align: left;
    padding: 10px 0;
}

.learnerTable tr {
    border-bottom: 1px solid #E8E8E8;
}

.learnerTable .removeButton{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: underline;
    color: #43B061;
    text-transform: uppercase;
}

.notificationDot{
    width: 10px;
    height: 10px;   
    border-radius: 100px;
    background: var(--secondary-color);
    margin-right: 15px;
}
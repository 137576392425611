.showType{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}

.main h4{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.main{

}

.navButton{
    width:40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid var(--primary-color);
    flex-shrink: 0;   
}

.dateText{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

.scheduleButton{
    padding: 15px 20px 15px 20px;
    border-radius: 10px;
    background-color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #FFFFFF;
}

.monthButton, .dayButton{
    border: 1px solid var(--primary-color)
}

.monthButton, .weekButton, .dayButton{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    height: 28px;
    color: var(--primary-color);
}

.dayButton{
    width: 54px;
    border-radius: 5px 0px 0px 5px;
}

.weekButton{
    width: 65px;
    border-top: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
}

.monthButton{
    width: 69px;
    border-radius: 0px 5px 5px 0px;
}

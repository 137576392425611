.updateWrapper, .calendarWrapper{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 20px;
}

.calendarWrapper{
    border-right: 1px solid #E8E8E8
}

.cardCatainer, .scheduleButton{
    box-shadow: 0px 2px 2px 0px #0000000D;
    padding: 15px 20px;
    border-radius: 10px;
    background: #FFFFFF;
    font-size: var(--font-size);
    font-weight: 400;
    line-height: 27px;
    color: var(--font-color)
}

.scheduleButton{
    color: #FFFFFF;
    background: var(--primary-color);
    font-size: 16px;
}

.newLessonStatsCard{
    width: 100%;
    height: 71px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #CACACA;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.cancelButton, .saveButton{
    width: fit-content;
    height: 51px;
    padding: 15px 20px 15px 20px;
    border-radius: 10px;
    background: #FA6D6D;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #FFFFFF;
}

.saveButton{
    background: var(--primary-color);
}





.cardContainer{
    padding: 15px;
    border-radius: 20px;
}

.timetableButton{
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: underline;
    color: #43B061;
}

.statisticsContainer{
    width: 100vw;
    overflow-x: auto;
    padding: 0 30px;
}

.infoCard{
    width: 222px;
}

.viewButton{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}

.viewButton{
    color: var(--primary-color);
    font-weight: 600;
    text-decoration: underline;
}

.resourcePill{
    width: fit-content;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
    color: #AF80CC;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    background-color: #ECE3F2;
}

.resourceCard{
    padding: 15px;
}

.resourceCard h4{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
}

.lessonDetailsWrapper{
    height: calc(100vh - 80px);
    overflow-y: auto;
}
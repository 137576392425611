.updateWrapper, .tableWrapper{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 20px;
}

.updateWrapper h3{
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
}

.updateWrapper h5{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
}

.tableWrapper{
    border-right: 1px solid #E8E8E8
}

.cardCatainer{
    box-shadow: 0px 2px 2px 0px #0000000D;
    padding: 15px 20px;
    border-radius: 10px;
    background: #FFFFFF;
    font-size: var(--font-size);
    font-weight: 400;
    line-height: 27px;
    color: var(--font-color)
}

.userImage{
    width: 180px;
    height: 180px;
    border-radius: 100px;
    margin-bottom: 20px;
}
.learnerTable tr th, .learnerTable tr td{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #262626;
    text-align: left;
    padding: 10px 0;
}

.learnerTable tr {
    border-bottom: 1px solid #E8E8E8;
}

.learnerTable .removeButton{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: underline;
    color: #43B061;
}

.learnerTable .capsule{
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    color: #AF80CC;
    background: #ECE3F2;
    width: fit-content;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
}
.main{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 0;
}

.profileWrapper{
    width: fit-content;
}

.profileWrapper h3{
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
}

.profileWrapper h5{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
}

.listCard{
    width: 100%;
    height: 61px;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px #0000000D;
    background: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
}

.userImage{
    width: 180px;
    height: 180px;
    border-radius: 100px;
    margin-bottom: 20px;
    position: relative;
}

.userImage .iconContainer{
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 10px;
    right:  -10px;
    border-radius: 50px;
    border: 2px solid #43B061;
    background: #FFFFFF;
}
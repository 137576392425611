.main{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 0;
}

.main button{
    width: 100%;
    padding: 10px 0px;
    border-radius: 8px;
    color: #FFFFFF;
    background: var(--primary-color);
    border: none;
    margin-top: 10px;
}

.searchWrapper{
    position: relative;
    width: 100%;
}

.filterCard{
    position: absolute;
    top: 60px;
    left: 0px;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 8px 0px #00000066;
    min-height: 100px;
}

.radioLabel{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #000000;
}

.checkboxContainer input[type=checkbox]{
    visibility:hidden;
    -webkit-appearance: none;
}

.checkboxContainer input[type='checkbox']:after {
    width: 18px;
    height: 18px;
    /* border-radius: 15px; */
    position: relative;
    background-color: transparent;
    border: 1.5px solid rgba(208,208,208, 0.5);
    border-radius: 5px;
    content: '';
    margin-top: 10px;
    display: inline-block;
    visibility: visible;
}

.checkboxContainer input[type='checkbox']:checked:after {
    width: 18px;
    height: 18px;
    /* border-radius: 15px; */
    position: relative;
    background: var(--primary-color);
    border: 1.5px solid var(--primary-color);
    border-radius: 5px;
    content: '';
    display: inline-block;
    visibility: visible;
}

.addPillCard{
    background-color: #f5f5f5;
    border-radius: 100px;
    padding: 5px 10px;
    font-size: 14px;
    width: fit-content;
}

.cardCatainer{
    box-shadow: 0px 2px 2px 0px #0000000D;
    height: 102px;
    padding: 15px 20px;
    gap: 20px;
    border-radius: 10px;
    background: #FFFFFF;
    font-size: var(--font-size);
    font-weight: 400;
    line-height: 27px;
    color: var(--font-color)
}

.subText{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
}
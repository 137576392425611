.inputWrapper{
    border: 1px solid #CACACA;
    width: 100%;
    height: 51px;
    padding: 15px;
    gap: 10px;
    border-radius: 10px;
    position: relative;
}

  /* Reset browser-specific styles */
  .inputWrapper select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-color: transparent;
    margin-top: -5px;
  }
  
  /* Remove the blue border on focus */
  .inputWrapper select:focus {
    outline: none;
    box-shadow: none;
  }

.inputWrapper input{
   border: none;
   width: 100%;
   background: none;
}

.inputWrapper input:focus{
    outline: none;
}

.inputWrapper input::placeholder{
    color: #CACACA;
}

.inputWrapper label{
    position: absolute;
    top: -12px;
    left: 8px;
    color: #959595;
    background: #FFFFFF;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
}
.main{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 0;
}

.detailsCard{
    box-shadow: 0px 2px 2px 0px #0000000D;
    height: 402px;
    padding: 15px 20px;
    border-radius: 10px;
    background: #FFFFFF;
}

.tabContainer{
    padding: 10px 15px;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--primary-color);
}



.fixedWidth {
    width: 100px; /* Or any other fixed width */
}
.flexGrow {
    width: auto;
}


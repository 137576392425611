.main{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding-top: 40px;
    padding-bottom: 100px;
}



.cardContainer{
    padding: 15px;
    border-radius: 20px;
}

.timetableButton{
    width: fit-content;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: underline;
    color: #43B061;
}

.statisticsContainer{
    width: 100vw;
    overflow-x: auto;
    padding: 0 30px;
}

.infoCard{
    width: 222px;
}

.viewButton{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}

.viewButton{
    color: var(--primary-color);
    font-weight: 600;
    text-decoration: underline;
}

.resourcePill{
    width: fit-content;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
    color: #AF80CC;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    background-color: #ECE3F2;
}

.resourceCard{
    padding: 15px;
}

.resourceCard h4{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
}

.lessonDetailsWrapper{
    height: calc(100vh - 80px);
    overflow-y: auto;
}
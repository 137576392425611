.updateWrapper, .calendarWrapper{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 20px;
}

.calendarWrapper{
    border-right: 1px solid #E8E8E8
}





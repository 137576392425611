@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --primary-color: #43B061;
  --secondary-color: #E8B241;
  --font-color: #262626;
  --font-size: 16px;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: "Poppins", sans-serif;
}

/* src/MyCalendar.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.custom-calendar {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.custom-calendar .rbc-toolbar {
  /* background-color: #4a90e2; */
  color: white;
  /* font-family: 'Roboto', sans-serif; */
}

.custom-calendar .rbc-toolbar button {
  color: white;
  /* font-family: 'Roboto', sans-serif; */
}

.custom-calendar .rbc-toolbar button:hover {
  background-color: #357ABD;
}

.custom-calendar .rbc-event {
  background-color: #FFFFFF;
  border-radius: 5px;
  font-size: 14px;
  line-height: 21px;
  color: #262626;

  /* font-family: 'Roboto', sans-serif; */
}

.custom-calendar .rbc-event:hover,  .custom-calendar.calendar-curriculum .rbc-event{
  background-color: var(--primary-color);
  color: #FFFFFF;
}

.custom-calendar .rbc-today {
  background-color: #CACACA;
  /* font-family: 'Roboto', sans-serif; */
}

.custom-calendar .rbc-month-view {
  border: 1px solid #ddd;
  /* font-family: 'Roboto', sans-serif; */
}

.custom-calendar .rbc-month-row {
  border: 1px solid #E8E8E8;
  /* min-height: 10px; */
}


.custom-calendar .rbc-date-cell {
  padding: 5px;
  height: 100%; /* Make sure date cells fill the row height */
}

.custom-calendar .rbc-date-cell.rbc-now {
  /* background-color: #4caf50; */
  border-radius: 10px;
  /* color: white; */
  /* font-family: 'Roboto', sans-serif; */
}

.custom-calendar .rbc-show-more {
  background-color: #4a90e2;
  color: white;
  border-radius: 5px;
  /* font-family: 'Roboto', sans-serif; */
}

.custom-calendar .rbc-show-more:hover {
  background-color: var(--primary-color);
}

/* Ensure the events also fit within the new row height */
.custom-calendar .rbc-row-content {
  border-radius: 10px;
}

.custom-calendar .rbc-month-header {
  border: none !important; /* Remove border */
  font-weight: 400; /* Reduce font weight */
}

.custom-calendar .rbc-header {
  font-weight: 400;
  border: none !important;
  padding: 8px 0;
}

/* Additional styles for rounded corners on the calendar itself */
.custom-calendar .rbc-month-view {
  padding: 20px;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px 2px 2px 0px #0000000D;
  border: none; /* Ensure header font weight is reduced */
  min-height: 808px;
}

/* Add border radius to the first cell in the first row */
.custom-calendar .rbc-month-row:first-child .rbc-date-cell:first-child {
  border-top-left-radius: 15px !important;
}

/* Add border radius to the last cell in the first row */
.custom-calendar .rbc-month-row:first-child .rbc-date-cell:last-child {
  border-top-right-radius: 15px;
}

/* Remove borders from day cells */
/* .custom-calendar .rbc-day-bg{
  border: none;
} */

/* .custom-calendar .rbc-time-content  > * + * > * {
  border-left: none;
} */





.custom-calendar .rbc-time-view {
  border: none !important;
}

.custom-calendar .rbc-time-content {
  border: none !important;;
}

.custom-calendar  .rbc-time-content .rbc-today,.custom-calendar .rbc-time-header .rbc-today{
  background: none !important;
}

.custom-calendar .rbc-event-label {
  display: none !important;
}

.custom-calendar .rbc-timeslot-group {
  min-height: 67px;
}

.custom-calendar .rbc-day-slot .rbc-event {
  border: none !important;
}


.custom-calendar .rbc-day-slot {
  border: none !important;
}

.custom-calendar .rbc-time-header-gutter {
  border: none !important;
}

.custom-calendar .rbc-time-header-content {
  border: none !important;
}

.custom-calendar  .rbc-day-bg, .rbc-time-content > * + * > * {
  border-left: none !important;
}



.main h3{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.descriptionCard{
    width: 100%;
    height: fit-content;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px #0000000D;
    background: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}
.updateWrapper, .liveStreamWrapper{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 20px; 
}

.liveStreamWrapper{
    border-right: 1px solid #E8E8E8
}

.liveStreamContainer{
    width: 100%;
    height: 40vw;
    padding: 0px 0px 20px 0px;
    border-radius: 20px;
    background: #FFFFFF;
}

.learnerTable tr th, .learnerTable tr td{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #262626;
    text-align: left;
    padding: 10px 0;
}

.learnerTable tr {
    border-bottom: 1px solid #E8E8E8;
}

.learnerTable .removeButton{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-decoration: underline;
    color: #43B061;
    text-transform: uppercase;
}

.chatWrapper{
    height: calc(100vh - 300px);
    width: 100%;
    margin-top: 20px;
}

.messageInput{
    width: 100%;
    min-height: 49px;
    padding: 14px 20px 14px 20px;
    border-radius: 100px;
    border: 1px solid #E8E8E8
}


.main{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 0;
}

.cardContainer{
    width: 100%;
    height: fit-content;
    padding: 20px;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px 0px #0000000D;
}

.saveButton{
    width: 100%;
    height: 51px;
    padding: 15px 20px 15px 20px;
    border-radius: 10px;
    background: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
}

.cancelButton{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: var(--font-color);
    width: 100%;
    height: 38px;
    padding: 10px 0px;
    border-radius: 10px;
    box-shadow: 0px 2px 2px 0px #0000000D;
    background: #CACACA;
}

.viewLessonsButton{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: var(--primary-color);
    text-decoration: underline;
}

/* .updateWrapper{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 20px;
} */

.updateListCard{
    box-shadow: 0px 2px 2px 0px #0000000D;
    background: #FAFAFA;
    width: 100%;
    padding: 0 20px;
    border-radius: 10px;
    padding: 20px;
    margin-top: 40px;
}

.innerCard{
    height: 61px;
}

.updateListCard span{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.showType{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}

.updateListCard h4{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.updateContainer, .viewLessonsButton{
    box-shadow: 0px 2px 2px 0px #0000000D;
    padding: 15px 20px;
    border-radius: 10px;
    background: #FFFFFF;
}

.capsule{
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
    background: #F6EEDF;
    width: fit-content;
}

.capsule span{
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    color:#CA9835;
}

.updateContainer h5{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}

.viewLessonsButton{
    padding: 10px 15px;
}

.main{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 0;
}

.addButton{
    width: fit-content;
    height: 51px;
    padding: 15px 20px 15px 20px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #FFFFFF;
    background: var(--primary-color);
    flex-shrink: 0;
}
.wrapper{
    height: calc(100vh - 80px);
    min-height: 100vh;
    padding-bottom: 60px;
}

.wrapper h2{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.backButton{
    width: fit-content;
}
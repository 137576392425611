/* src/Sidebar.css */

.sidebar {
    position: fixed;
    top: 0;
    right: -507px; /* Initially hidden off screen */
    width: 507px;
    border-radius: 20px 0px 0px 20px;
    height: 100%;
    background-color: #F9F9F9;
    /* color: white; */
    padding: 30px 20px 20px 20px;
    transition: right 0.3s ease;
    z-index: 1000;
    box-shadow: 0px 4px 50px 0px #00000066;
  }
  
  .sidebar.open {
    right: 0; /* Slide in */
  }
  
  .closeBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(38, 38, 38, 0.6);
    backdrop-filter: blur(2px); /* Apply blur effect */
    z-index: 999; /* Behind the sidebar */
  }

  .sidebar h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }

  .lessonTitleWrapper p{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
  }

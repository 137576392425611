#switcher {
  width: 95px;
  height: 35px;
  border-radius: 50px;
  position: relative;
  background-color: #F9F9F9 !important;
  border: 1px solid #DDE0E8;
 

  .activeText{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 36px;
  }


  .handle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
    transition: 0.5s;
    background-color: #CACACA !important;
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
}

#switcher.active {
  background-color: #E9F4EC !important;
  border: 1px solid #DDE0E8;
  .activeText{
    margin-left: 13px;
  }
  .handle {
    left: calc(100% - 33px);
    background-color: #43B061 !important;
  }
}

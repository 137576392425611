.newLessonCard{
    width: 60%;
    height: fit-content;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 4px 50px 0px #00000066;
    background: #FFFFFF;
}

.newLessonCard h4{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.viewDetailsButton{
    width: fit-content;
}

.viewDetailsButton span{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: var(--primary-color);
    text-decoration: underline;
}
.aboutLessonCard{
    width: 100%;
    height: 71px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #CACACA
}

.aboutLessonCard h5, .aboutLessonCard p, .viewButton{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}

.aboutLessonCard h5{
    font-weight: 600;
    margin-top: 5px;
}
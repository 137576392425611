.container{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px 0px #0000000D;
    margin-top: 30px;
}

.iconWrapper{
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px 0px #00000026;
    flex-shrink: 0;
}

.titleText{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.subTitleText{ 
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}




.titleWrapper {
    /* border-left: 3px solid #76A3CC; */
    margin-bottom: 23px;
}

.titleWrapper h3{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
}

.showTypes{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}

.main{
    padding: 50px 40px 60px 40px;
    
}

.inputWrapper{
    width: 600px;
}

.continueButton{
    width: 100%;
    padding: 20px 0px;
    border-radius: 10px;
    color: #FFFFFF;
    background: var(--primary-color);
}

.linkText{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #43B061;
    text-decoration: underline;
}

.titleText{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
}

.errorText{
    color: red;
    text-align: left;
}
.main{
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 40px 0;
}

.main button{
    width: 100%;
    padding: 10px 0px;
    border-radius: 8px;
    color: #FFFFFF;
    background: var(--primary-color);
    border: none;
    margin-top: 20px;
}
.wrapper{
    background: #F5F6FA;
    min-height: 100vh;
}

.main{
    position: relative;
    overflow: auto;
}

.header{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
    background: #FAFAFA;
    padding: 0 30px;
    box-shadow: 0px 4px 20px 0px #0000000D;

}

.content{
    padding: 80px 20px 50px 20px;
}

.iconContainer, .userImage{
    border: 1px solid var(--primary-color);
    width: 45px;
    height: 45px;
    border-radius: 10px;
}

.userImage{
    border: none;
    background: grey;
}

.profileName h4{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: right;
    margin: 0;
}

.profileName h5{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: right;
    margin: 0;
}
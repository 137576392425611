.wrapper{
    border-right: 1px solid #F4F6FA;
    box-shadow: 0px 2px 2px 0px #0000000D;
    background: #FFFFFF;
    min-height: 100vh;
    width: 300px;
}

.logoContainer{
    /* padding: 40px 30px; */
    height: 140px;
}

.navWrapper{
    height: calc(100vh - 140px);
    padding-bottom: 30px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.navWrapper::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.iconWrapper{
    box-shadow: 0px 2px 5px 0px #00000026;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    background-color: #FFFFFF;
}

.navContainer{
    padding: 15px 20px;
    border-top: 1px solid #E1E1E1
}

.navContainer .titleText{
    font-size: var(--font-size);
    font-weight: 400;
    line-height: 27px;
}

.activeTab{
    background-color: var(--primary-color);
    color: #FFFFFF;
}


